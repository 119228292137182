import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { INVENTORY_SERVICE_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class DealerReportingClient {
  static inventoryServiceUrl = INVENTORY_SERVICE_URL;

  static downloadInventoryPricingReportInCsv(
    user,
    inventoryReportColumnHeadersForDealer
  ) {
    const dealerId = user.getDealerIdsWithSDLR(false);
    const data = {
      columnHeaders: inventoryReportColumnHeadersForDealer,
    };
    const url = `${this.inventoryServiceUrl}/dealer/${dealerId}/inventory-pricing-report`;
    return axios
      .post(url, data, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static downloadInventoryPricingHistoryReport(
    user,
    inventoryReportColumnHeadersForDealer
  ) {
    const dealerId = user.getDealerIdsWithSDLR(false);
    const data = {
      columnHeaders: inventoryReportColumnHeadersForDealer,
    };
    const url = `${this.inventoryServiceUrl}/dealer/${dealerId}/inventory-pricing-history-report`;
    return axios
      .post(url, data, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }
}
