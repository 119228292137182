import React, { Component } from "react";
import FileBrowser from "../../../../shared/fileBrowser/FileBrowser";
import Button from "@material-ui/core/Button";
import styles from "./UploadDealerPricing.module.scss";
import Grid from "@material-ui/core/Grid";
import excelIcon from "../../../../imgs/excel-icon.svg";
import ClearIcon from "@material-ui/icons/Clear";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { InfoIconWithMessage } from "../../../../shared/infoIcon/InfoIcon";
import UploadDealerPriceClient from "../../../../shared/clients/UploadDealerPriceClient";
import SubNav from "../../../../shared/subnav/SubNav";
import { BRANDS } from "../../../accessories/Brands";
import LoadDealerPricing from "./LoadDealerPricing";
import ShowInventoryData from "./ShowInventoryData";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import { REGEX_LIST } from "../../../../common/Constants";
import commonStyles from "../../../../styles/common/CommonStyles.module.scss";
import { PricesApplied } from "../../../../shared/feedback/PricesApplied";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import MarketPreferencesClient from "../../../../shared/clients/MarketPreferencesClient";
import { base64FileDownload } from "../../../../utils/FileDownloadUtil";
import { withStyles } from "@material-ui/core/styles";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import PublishOutlined from "@material-ui/icons/PublishOutlined";
import { KeyboardArrowDown } from "@material-ui/icons";
import subNavStyles from "../../../../shared/subnav/subNavStyles";
import ErrorProcessingDialog from "../../../../errors/ErrorProcessingDialog";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const StyledSaveAltIconWhite = withStyles({
  root: {
    height: 18,
    margin: 2,
    width: 18,
    padding: 0,
    color: "#fff",
  },
})(SaveAltIcon);

const StyledPublishAltIconWhite = withStyles({
  root: {
    width: 22,
    padding: 0,
    color: "#fff",
    textAlign: "center",
    height: "100%",
    marginTop: "-3px",
  },
})(PublishOutlined);

const PickUpStyledMenuItem = withStyles({
  root: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    margin: 0,
    fontSize: 14,
    color: "#102b4e",
    lineHeight: 1.57,
    letterSpacing: 1.5,
    fontFamily: "FordAntenna-Regular, sans-serif",
  },
})(MenuItem);

const PickUpFormControl = withStyles({
  root: {
    width: 250,
    background: "linear-gradient(to bottom, #164069, #102b4e)",
  },
})(FormControl);

const SelectCustom = withStyles({
  root: {
    background: "linear-gradient(to bottom, #164069, #102b4e)",

    color: "red",
  },
})(Select);

class UploadDealerPricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      displayDetails: false,
      enablePublish: false,
      effectiveDate: "",
      displayError: false,
      openModal: false,
      isEditable: false,
      editButtonDisable: {},
      allChecked: false,
      selectedPrices: [],
      fileExtractingData: [],
      pricesApplied: false,
      conversionRate: "1.0",
      pricesUploaded: false,
      numberOfPricesUploaded: 0,
      numberOfPricesPublished: 0,
      currentFilter: "all.all",
      errorMsg: null,
      ialErrorOpen: false,
    };
    this.restrictDropdown = false;
    this.removeFile = this.removeFile.bind(this);
    this.isMEXMarket = this.props.user.market === "MEX";
  }

  componentDidMount() {
    this.setState({
      selectedPrices: this.props.dealerInventoryPrices,
      effectiveDate: new Date(),
    });
    this.getMarketConversionRate();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.dealerInventoryPrices &&
      this.props.dealerInventoryPrices.length !==
        prevProps.dealerInventoryPrices.length
    ) {
      this.setState({ selectedPrices: this.props.dealerInventoryPrices });
    } else if (
      this.props.dealerInventoryPrices &&
      prevProps.dealerInventoryPrices &&
      this.props.dealerInventoryPrices.length ===
        prevProps.dealerInventoryPrices.length
    ) {
      let shouldUpdate = false;
      prevProps.dealerInventoryPrices.forEach((prevPrice, index) => {
        if (
          this.props.dealerInventoryPrices[index] &&
          (prevPrice.currencyAdjustedPrice !==
            this.props.dealerInventoryPrices[index].currencyAdjustedPrice ||
            prevPrice.published !==
              this.props.dealerInventoryPrices[index].published ||
            prevPrice.modifiedDate !==
              this.props.dealerInventoryPrices[index].modifiedDate)
        ) {
          shouldUpdate = shouldUpdate || true;
        }
      });
      if (shouldUpdate) {
        this.setState({ selectedPrices: this.props.dealerInventoryPrices });
      }
    }
  }

  onFileSelected = (file) => {
    let validFileType = false;
    if (
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      validFileType = true;
    }
    if (file.size > 1000000 || !validFileType) {
      //Need to display an error message like file exceeds 70kb
      this.setState({ displayError: true, file: null, displayDetails: false });
    } else {
      this.setState({
        file: file,
        displayDetails: true,
        displayError: false,
        enablePublish: true,
        effectiveDate: new Date(),
      });
    }
  };

  handleInputChange = (event) => {
    if (
      event.target.value !== this.state.currentFilter &&
      event.target.value !== undefined
    ) {
      this.setState({ currentFilter: event.target.value });

      const property = event.target.value.split(".")[0];
      const value = event.target.value.split(".")[1];
      const expression = { property: property, value: value };

      this.props.reloadWithFilteredData(expression);
    }
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };

  getCount = () => {
    const prices = this.state.selectedPrices;
    let count = 0;
    prices.length > 0 &&
      prices.forEach((price) => {
        if (price.checked) {
          count += 1;
        }
      });
    return count > 0 ? count : null;
  };

  getCountForEdit = () => {
    const prices = this.state.selectedPrices;
    let isChanged = false;
    prices &&
      prices.length > 0 &&
      prices.forEach((price) => {
        if (!price.published) {
          isChanged = isChanged || true;
        } else {
          const propsPrice = this.props.dealerInventoryPrices.find(
            (propsprice) => price.vin === propsprice.vin
          );
          if (
            propsPrice &&
            propsPrice.currencyAdjustedPrice !== price.currencyAdjustedPrice
          ) {
            isChanged = isChanged || true;
          }
        }
      });
    if (!isChanged && !this.state.pricesApplied) {
      this.setState({ pricesApplied: true });
    }
    return isChanged;
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
      displayDetails: false,
    }));
  };

  getMarketConversionRate = () => {
    MarketPreferencesClient.getConversionRate(this.props.user)
      .then((response) => {
        let rate = 1.0;
        if (response.result.conversionRate) {
          rate = response.result.conversionRate;
        }
        this.setState({
          conversionRate: rate,
        });
      })
      .catch(() => {})
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  EditReservationsHandler = () => {
    this.setState({
      isEditable: !this.state.isEditable,
    });
  };

  uploadToExtractFile = () => {
    this.uploadDealerPrice(0);
  };

  uploadDealerPriceToSave = () => {
    this.setState({ openModal: false });
    this.uploadDealerPrice(1);
  };

  uploadDealerPrice = (processType) => {
    this.setState({ enablePublish: false });
    this.props.hideOrShow(true);

    const formData = new FormData();
    formData.append("pricingDocument", this.state.file);
    formData.append("processType", processType);
    formData.append("conversionRate", this.state.conversionRate);

    this.addDealerInfoInFormData(this.props.user, formData);

    UploadDealerPriceClient.uploadDealerPrice(this.props.user, formData)
      .then((data) => {
        if (processType === 1) {
          this.removeFile();
          this.props.loadDealerInventoryPricingHistory();
          this.setState({
            numberOfPricesUploaded:
              data &&
              data.dealerPricesDataList &&
              data.dealerPricesDataList[0].dealerInvPriceData &&
              data.dealerPricesDataList[0].dealerInvPriceData.length,
          });
        } else {
          this.setState({
            fileExtractingData:
              data &&
              data.dealerPricesDataList &&
              data.dealerPricesDataList[0].dealerInvPriceData,
            numberOfPricesUploaded:
              data &&
              data.dealerPricesDataList &&
              data.dealerPricesDataList[0].dealerInvPriceData &&
              data.dealerPricesDataList[0].dealerInvPriceData.length,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        this.props.hideOrShow(false);
        if (processType === 0) {
          this.toggleModal();
        } else if (processType === 1) {
          this.setState({
            pricesUploaded: true,
          });
        }
      });
  };

  //Based on the type of current dealer,
  // this method returns appropriate dealers info for Inventory service call
  addDealerInfoInFormData = (user, formData) => {
    //all
    user.dealerGroup.forEach((dealer) => {
      const dealerInfo = {
        dealerId: dealer.completeDealerId,
        commonId: dealer.commonId,
        mainDealer: dealer.dealerType === "HUB",
      };
      formData.append("dealerInfoJson", JSON.stringify(dealerInfo));
    });
  };

  removeFile = () => {
    document.getElementById("xls-file").value = "";
    this.setState({
      file: null,
      displayDetails: false,
      enablePublish: true,
      effectiveDate: "",
    });
  };

  getSubNav = () => {
    const CustomSubNavTabBody = () => {
      return (
        this.props.enableUpload !== undefined && (
          <>
            <div className={styles.uploadTermsAndConditions}>
              {this.props.enableUpload ? (
                <HasPermissionTo
                  perform={["dealerInventoryUpload:write"]}
                  permissions={this.props.user.permissions.rolePermissions}
                  render={() => {
                    return (
                      <div className={`${styles.tools}`}>
                        <div>
                          {this.props.user.dealerType === "HUB" && (
                            <>
                              <div
                                className={`${styles.download} ${styles.downloadPointer} ${styles.downloadFile}`}
                              >
                                <a
                                  href={null}
                                  onClick={this.downloadInvPriceTemplate}
                                  className={`${styles.download}`}
                                >
                                  <StyledSaveAltIconWhite />

                                  {this.props.t(
                                    "UploadDealerPrice.downloadTemplate"
                                  )}
                                </a>
                              </div>
                              <div
                                className={`${styles.tools} ${styles.download}`}
                              >
                                <div
                                  className={`${styles.download}`}
                                  title={this.props.t(
                                    "UploadDealerPrice.tipText"
                                  )}
                                >
                                  <FileBrowser
                                    fileType=".xls,.xlsx"
                                    onFileSelected={this.onFileSelected}
                                    id="xls-file"
                                  >
                                    <StyledPublishAltIconWhite />
                                    <a>
                                      {this.props.t(
                                        "UploadDealerPrice.fileHeaderText"
                                      )}
                                    </a>
                                  </FileBrowser>

                                  {this.state.displayError && (
                                    <div className={styles.warningDiv}>
                                      <div className={styles.warning}></div>
                                      <div className={styles.errorText}>
                                        {this.props.t(
                                          "UploadDealerPrice.fileError"
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        <div>
                          <PickUpFormControl
                            fullWidth={false}
                            className={`${styles.download}`}
                          >
                            <SelectCustom
                              IconComponent={() => (
                                <KeyboardArrowDown style={subNavStyles.icon} />
                              )}
                              name="filter"
                              id="pickUpfilter"
                              data-testid="filterDropDown"
                              className={styles.dropdown}
                              value={this.state.currentFilter}
                              onChange={this.handleInputChange}
                            >
                              {this.props.filters &&
                                this.props.filters.map(
                                  (recordForFilterDropDown) => {
                                    return (
                                      <PickUpStyledMenuItem
                                        key={recordForFilterDropDown.value}
                                        value={recordForFilterDropDown.value}
                                      >
                                        <div>
                                          {recordForFilterDropDown.text}
                                        </div>
                                      </PickUpStyledMenuItem>
                                    );
                                  }
                                )}
                            </SelectCustom>
                          </PickUpFormControl>
                        </div>
                      </div>
                    );
                  }}
                />
              ) : (
                <div id="termsHeaderWithWarning" className="pagePadding">
                  <InfoIconWithMessage
                    message={[
                      this.props.t("BusinessSettings.termsandConditionsHeader"),
                      " ",
                      <span
                        className={styles.previewTitleWarning}
                        key="maximumUploadWarning"
                      >
                        {this.props.t("BusinessSettings.maximumUploadWarning")}
                      </span>,
                    ]}
                  />
                </div>
              )}
            </div>
            {this.state.displayDetails && (
              <>
                <Grid
                  container
                  className={`${styles.effectiveBox} pagePaddingLeft`}
                  id="effectiveRow"
                >
                  <Grid item xs={4} className={styles.effectiveDateRow}>
                    <div className={styles.fileNameTextBox}>
                      <div>
                        <img src={excelIcon} />
                        <p>{this.state.file.name}</p>
                      </div>
                      <div
                        className={styles.clearImage}
                        id="removeFile"
                        onClick={this.removeFile}
                      >
                        <ClearIcon />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={8} className={styles.effectiveDateRow}>
                    <span className={styles.effectiveText}>
                      Effective From{" "}
                    </span>
                    <div className={styles.effectiveDateText}>
                      {moment(this.state.effectiveDate).toString()}
                    </div>
                  </Grid>
                </Grid>
                <div className={styles.publishButton}>
                  <Button
                    type="button"
                    onClick={this.uploadToExtractFile}
                    className="greenBtn"
                    id="publish-action-btn"
                    disabled={!this.state.enablePublish}
                  >
                    {this.props.t("UploadDealerPrice.publish")}
                  </Button>
                </div>
              </>
            )}
          </>
        )
      );
    };
    return (
      <SubNav
        id="SubNavBarHere"
        {...this.props}
        title={this.props.t("UploadDealerPrice.header")}
        displayDetails={this.state.displayDetails}
        renderSubHeader={false}
        showCustomDropdown={false}
        subHeader={null}
        restrictDropdown={this.restrictDropdown}
        dropDownValues={BRANDS}
        customSubNavTabBody={CustomSubNavTabBody}
      />
    );
  };

  downloadInvPriceTemplate = () => {
    this.props.hideOrShow(true);
    UploadDealerPriceClient.downloadInvPriceTemplate(
      this.props.user,
      this.props.t("UploadDealerPrice.templateColumnNames")
    )
      .then((res) => {
        const filename =
          this.props.t("UploadDealerPrice.templateName") + ".xlsx";
        const BOM = "\uFEFF";
        const response = BOM + res;
        base64FileDownload(
          decodeURIComponent(response),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          filename
        );
        this.props.hideOrShow(false);
      })
      .catch(() => {});
  };

  //Based on the type of current dealer,
  // this method returns appropriate Dealer JSON objects for IAL publish
  addDealerObjectsInIALrequest = (user) => {
    const listOfDealers = [];
    let newDealerObj = {};

    //all
    user.dealerGroup.forEach((dealer) => {
      newDealerObj = {
        dealerId: dealer.completeDealerId,
        commonId: dealer.commonId,
        mainDealer: dealer.dealerType === "HUB",
      };
      listOfDealers.push(newDealerObj);
    });

    return listOfDealers;
  };

  updateInventoryPrice = (event) => {
    // Edit functions
    const originalDisables = this.state.editButtonDisable;
    if (!event.target.value.match(REGEX_LIST.number) || !event.target.value) {
      originalDisables[event.target.name] = true;
    } else {
      originalDisables[event.target.name] = false;
    }

    // change the current-editing value but retain the other records edited values as well
    const newPrices = this.state.selectedPrices.map((price) => {
      const newPrice = { ...price };
      if (event.target.name === newPrice.vin) {
        newPrice.currencyAdjustedPrice = event.target.value;
        newPrice.checked = false;
        newPrice.priceModified = true;
        if (this.props.user && this.props.user.market === "ARG") {
          newPrice.usdCustomerPrice =
            this.state.conversionRate * event.target.value;
        }
        if (this.props.user && this.isMEXMarket) {
          newPrice.vehicleSuppressedByDealer = !(event.target.value > 0);
        }
      }
      return newPrice;
    });
    this.setState({
      editButtonDisable: originalDisables,
      selectedPrices: newPrices,
      pricesApplied: false,
    });
  };

  clickCheckBox = (event) => {
    const index = event.target.value;
    const originalState = this.state.selectedPrices;
    if (originalState && originalState[index]) {
      originalState[index].checked = !originalState[index].checked;
      if (this.isMEXMarket) {
        //MEX changes related to vehicleSuppressedByDealer flag
        originalState[index].vehicleSuppressedByDealer =
          !originalState[index].vehicleSuppressedByDealer;
        originalState[index].published = false;
      }
      const filteredPrice = originalState.filter(
        (invprice) => invprice.checked === true
      );
      this.setState({
        selectedPrices: originalState,
        allChecked: this.checkIfAllChecked(filteredPrice),
        pricesApplied: false,
      });
    }
  };

  checkIfAllChecked = (filteredPrice) => {
    return filteredPrice.length === this.state.selectedPrices.length;
  };

  fillAllCheckBox = () => {
    const checkedOrNot = !this.state.allChecked;
    const allPrices = this.state.selectedPrices;
    allPrices.forEach((invprice) => {
      if (invprice.vin !== "") {
        if (invprice.priceModified) {
          invprice.checked = true;
        } else {
          invprice.checked = checkedOrNot;
        }
      }
    });
    this.setState({
      selectedPrices: allPrices,
      allChecked: checkedOrNot,
    });
  };

  updatePriceModifiedFlag = (modifiedPrices) => {
    modifiedPrices.forEach((modified) => {
      this.state.selectedPrices.forEach((price) => {
        if (modified.vin === price.vin) {
          price.priceModified = false;
          price.checked = false;
        }
      });
    });
  };

  uploadInventoryPrices = () => {
    const modifiedPrices = this.state.selectedPrices.filter(
      (price) => price.checked
    );
    this.props.hideOrShow(true);

    const listOfDealers = this.addDealerObjectsInIALrequest(this.props.user);

    const requestBody = {
      dealerInfos: listOfDealers,
      prices: modifiedPrices,
    };
    let reload = false;
    UploadDealerPriceClient.persistAndPublishToIAL(this.props.user, requestBody)
      .then((data) => {
        if (data && data.message && data.message.code === "200") {
          //reload the data
          reload = true;
        } else if (data && data.message && data.message.code === "500") {
          //Throwing Error to show popup.
          throw new Error("IAL Call Failed:" + data.message.description);
        }
      })
      .catch(() => {
        // show alert message and reload
        const errorMsg = this.props.t("UploadDealerPrice.ialErrorMessage");
        this.setState({
          errorMsg: errorMsg,
          ialErrorOpen: true,
        });
      })
      .finally(() => {
        if (reload) {
          this.props.loadDealerInventoryPricingHistory();
          //and reset the states
          this.setState({
            numberOfPricesPublished: modifiedPrices.length,
            isEditable: false,
            editButtonDisable: {},
            allChecked: false,
            pricesApplied: true,
          });
        }
        this.props.hideOrShow(false);
      });
    this.updatePriceModifiedFlag(modifiedPrices);
  };

  render() {
    return (
      <div className="pageWrapper">
        {!this.props.fromQuickstart && this.getSubNav()}

        <ShowInventoryData
          {...this.props}
          open={this.state.openModal}
          cancel={this.handleClose}
          closeModal={this.toggleModal}
          showUsdCustomerPrice={this.props.t(
            "UploadDealerPrice.showUsdCustomerPrice"
          )}
          uploadDealerPriceToSave={this.uploadDealerPriceToSave}
          toggleModal={this.toggleModal}
          checkAllCheckBox={this.fillAllCheckBox}
          allChecked={this.state.allChecked}
          selectedPrices={this.state.fileExtractingData}
          conversionRate={this.state.conversionRate}
          isMEXMarket={this.isMEXMarket}
        />
        <div className={styles.title}>
          <h2 className="pagePaddingLeft" id="termsHeader">
            {this.props.t("UploadDealerPrice.title")}
          </h2>
        </div>

        <ValidatorForm
          id="modifyReservationForm"
          ref={this.props.formRef}
          onSubmit={this.uploadInventoryPrices}
        >
          <LoadDealerPricing
            {...this.props}
            showValidatdColumn={true}
            applyBtn={this.applyBtnHandler}
            showUsdCustomerPrice={this.props.t(
              "UploadDealerPrice.showUsdCustomerPrice"
            )}
            editMode={this.state.isEditable}
            editReservations={(e) => this.EditReservationsHandler(e)}
            clickCheckBox={(e) => this.clickCheckBox(e)}
            updateInventoryPrice={this.updateInventoryPrice}
            allChecked={this.state.allChecked}
            checkAllCheckBox={this.fillAllCheckBox}
            selectedPrices={this.state.selectedPrices}
            conversionRate={this.state.conversionRate}
            isMEXMarket={this.isMEXMarket}
          />
          {this.getCount() > 0 &&
          this.getCountForEdit() &&
          !this.state.pricesApplied ? (
            <div className={styles.buttonDiv}>
              <button id="saveBtn" className={commonStyles.greenBtn}>
                {`${this.props.t("ActionFooter.apply")} `}
              </button>
            </div>
          ) : (
            this.state.numberOfPricesPublished > 0 && (
              <PricesApplied
                success={this.state.pricesApplied}
                text={`${this.state.numberOfPricesPublished} ${this.props.t(
                  "ModifyReservationPrice.vinsPublishedSuccessfully"
                )}`}
              />
            )
          )}
        </ValidatorForm>
        <PricesApplied
          success={this.state.pricesUploaded}
          text={`${this.state.numberOfPricesUploaded} ${this.props.t(
            "ModifyReservationPrice.vinsUploadedSuccessfully"
          )}`}
        />
        {this.state.ialErrorOpen && (
          <ErrorProcessingDialog
            open={this.state.ialErrorOpen}
            close={() => {
              this.setState({ ialErrorOpen: false, displayDetails: false });
            }}
            body={this.state.errorMsg}
          />
        )}
      </div>
    );
  }
}

export default withTranslation("emp")(UploadDealerPricing);
