import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./styles/empCssLibs/variables.scss";
import "./fonts/ford.scss";
import "./fonts/lincoln.scss";
import * as serviceWorker from "./common/serviceWorker";
import App from "./apps/App";
import i18n from "./common/i18n.js";
import ErrorBoundary from "./errors/ErrorBoundary";

ReactDOM.render(
  <Suspense fallback="">
    <ErrorBoundary>
      <App i18n={i18n} />
    </ErrorBoundary>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
