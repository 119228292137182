import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { Route, Router } from "react-router-dom";
import { logMsg } from "../common/Logger";
import Loading from "../shared/loading/Loading";
import NSCApp from "./NSCApp";
import AccessDeniedAsBevDisabled from "../components/accessDenied/AccessDeniedAsBevDisabled";
import DealerApp from "./DealerApp";
import { ADFS_TOKEN, BRAND, PAGE, SALESCODE_KEY } from "../common/Constants";
import AuthenticationClient from "../shared/clients/AuthenticationClient";
import { ENV, SA_URL } from "../utils/EmpUtil";
import { displayError, is500, PAGE_ERROR } from "../errors/ErrorConstants";
import { I18nextProvider } from "react-i18next";
import i18n from "../common/i18n";

class App extends Component {
  static getTheToken(params) {
    let token = params.access_token;

    if (!token) {
      token = sessionStorage.getItem(ADFS_TOKEN);
    }
    return token;
  }

  static hideOrShow(show, setZIndex = false) {
    if (show) {
      App.loadingCount += 1;
      if (document.getElementById("loading")) {
        document.getElementById("loading").style.display = "block";
      }
      if (document.getElementById("appWrapper") && setZIndex) {
        document.getElementById("appWrapper").style.zIndex = "-1";
      }
    } else {
      if (App.loadingCount === 1) {
        if (document.getElementById("loading")) {
          document.getElementById("loading").style.display = "none";
        }
        if (
          document.getElementById("appWrapper") &&
          (document.getElementById("appWrapper").style.zIndex === "-1" ||
            document.getElementById("appWrapper").style.zIndex === -1)
        ) {
          document.getElementById("appWrapper").style.zIndex = "auto";
        }
      }
      if (App.loadingCount > 0) {
        App.loadingCount -= 1;
      }
    }
  }

  static createLocalUrl() {
    if (ENV !== "prod") {
      const token = sessionStorage.getItem(ADFS_TOKEN);
      const salesCode = sessionStorage.getItem(SALESCODE_KEY);
      const page = sessionStorage.getItem(PAGE);
      const brand = sessionStorage.getItem(BRAND);
      let stateParam = "";
      if (salesCode) {
        stateParam += ",salesCode=" + salesCode;
      }
      if (page) {
        stateParam += ",page=" + page;
      }
      if (brand) {
        stateParam += ",brand=" + brand;
      }

      if (stateParam !== "") {
        stateParam = "&state=" + stateParam;
      }
      sessionStorage.setItem(
        "localHost",
        `localhost:3000/#access_token=${token}${stateParam}`
      );
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      renderApp: false,
      hasError: [],
    };
    App.loadingCount = 0;

    App.hideOrShow = App.hideOrShow.bind(this);
  }

  componentDidMount() {
    const currentUrl = window.location.href;
    if (currentUrl && currentUrl.includes("/sa")) {
      window.location.href = SA_URL;
    }
    const params = this.getUrlParams();
    const token = App.getTheToken(params);
    let salesCode = params.salesCode || sessionStorage.getItem(SALESCODE_KEY);
    const page = params.page || sessionStorage.getItem(PAGE);
    const brand = params.brand || sessionStorage.getItem(BRAND);

    const authenticationRequest = {};
    if (salesCode) {
      authenticationRequest["salesCode"] = salesCode;
    }
    if (page) {
      authenticationRequest["page"] = page;
    }
    if (brand) {
      authenticationRequest["brand"] = brand;
    }

    App.hideOrShow(true);
    AuthenticationClient.status(token, authenticationRequest)
      .then((response) => {
        sessionStorage.setItem(ADFS_TOKEN, token);
        this.i18n = i18n();
        if (!salesCode) salesCode = response.salesCode;
        if (salesCode) sessionStorage.setItem(SALESCODE_KEY, salesCode);
        if (page) sessionStorage.setItem(PAGE, page);
        if (brand) sessionStorage.setItem(BRAND, brand);
        this.token = token;
        this.userDetails = response;
        sessionStorage.setItem("market", this.userDetails.market);
        this.userDetails.brand = "F";
        App.createLocalUrl();
        this.setState({ renderApp: true });
      })
      .catch((error) => {
        logMsg(error);
        if (is500(error)) {
          this.setState({ hasError: [PAGE_ERROR] });
        }
      })
      .finally(() => {
        App.hideOrShow(false);
      });
  }

  getUrlParams() {
    const hash = window.location.hash.substring(1);
    const params = {};
    hash.split("&").forEach((hk) => {
      if (hk.startsWith("state")) {
        hk.split(",").forEach((state) => {
          const temp = state.split("=");
          params[temp[0]] = temp[1];
        });
      } else {
        const temp = hk.split("=");
        params[temp[0]] = temp[1];
      }
    });
    return params;
  }

  isNsc = (userDetails) => userDetails.userType === "nsc";

  isDealer = (userDetails) => userDetails.userType === "dealer";

  render() {
    if (displayError(this.state.hasError)) {
      throw new Error(this.state.hasError[0]);
    }

    return this.state.renderApp ? (
      <I18nextProvider i18n={this.i18n}>
        <Router history={createBrowserHistory()}>
          <Loading brand={this.userDetails.brand} />
          <Route
            render={(props) => {
              if (this.isDealer(this.userDetails)) {
                return (
                  <DealerApp
                    {...props}
                    token={this.token}
                    isNsc={false}
                    i18n={this.i18n}
                    userDetails={this.userDetails}
                    hideOrShow={App.hideOrShow}
                  />
                );
              } else if (this.isNsc(this.userDetails)) {
                return (
                  <NSCApp
                    {...props}
                    token={this.token}
                    isNsc={true}
                    i18n={this.i18n}
                    userDetails={this.userDetails}
                    hideOrShow={App.hideOrShow}
                  />
                );
              } else {
                if (document.getElementById("loading")) {
                  return <AccessDeniedAsBevDisabled {...props} />;
                } else {
                  return null;
                }
              }
            }}
          />
        </Router>
      </I18nextProvider>
    ) : (
      <Loading />
    );
  }
}

export default App;
