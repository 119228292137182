import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class MarketPreferencesClient {
  static baseUrl = BASE_URL;

  static getReservationReleaseStatus(user) {
    const url = `${this.baseUrl}/preferences/release-status`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static saveReservationReleasePreferences(user, data) {
    const url = `${this.baseUrl}/preferences/reservation-release/save`;
    return axios
      .put(url, data, getHeaders(user.token))
      .then(() => Promise.resolve())
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getConversionRate(user) {
    const url = `${this.baseUrl}/conversions/marketConversionRate`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
