import UpperCaseText from "./UpperCaseText";

const IS_SA_MARKET = (market) => {
  return (
    "ARG" === UpperCaseText(market) ||
    "BRA" === UpperCaseText(market) ||
    "MEX" === UpperCaseText(market) ||
    "COL" === UpperCaseText(market) ||
    "PER" === UpperCaseText(market) ||
    "CHL" === UpperCaseText(market)
  );
};

const IS_FORD_BRAND = (brand) => {
  return "F" === brand;
};

const NULL_OR_UNDEFINED = (input) => {
  return input === null || input === undefined;
};

const NOTNULL_OR_NOTUNDEFINED = (input) => {
  return !NULL_OR_UNDEFINED(input);
};

const BASE_URL =
  typeof window !== "undefined" && window.EMP ? window.EMP.BASE_URL : "";

const SHARED_SERVICE_URL =
  typeof window !== "undefined" && window.EMP
    ? window.EMP.SHARED_SERVICE_URL
    : "";

const ENV = typeof window !== "undefined" && window.EMP ? window.EMP.ENV : "";

const AUTHORIZE_BASE_URL =
  typeof window !== "undefined" && window.AUTH_SERVICE
    ? window.AUTH_SERVICE.BASE_AUTHORIZE_URL
    : "";

const TRANSLATION_BASE_URL =
  typeof window !== "undefined" && window.EMP ? window.EMP.TRANSLATION_URL : "";

const SA_URL = typeof window !== "undefined" && window.SA ? window.SA.URL : "";

const INVENTORY_SERVICE_URL =
  typeof window !== "undefined" && window.INVENTORY_SERVICE
    ? window.INVENTORY_SERVICE.URL
    : "";

export {
  IS_FORD_BRAND,
  BASE_URL,
  SHARED_SERVICE_URL,
  AUTHORIZE_BASE_URL,
  TRANSLATION_BASE_URL,
  NOTNULL_OR_NOTUNDEFINED,
  NULL_OR_UNDEFINED,
  ENV,
  IS_SA_MARKET,
  SA_URL,
  INVENTORY_SERVICE_URL,
};
