import React, { Component } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Grid,
  Link,
} from "@material-ui/core";
import TranslationSpecific from "./TranslationSpecific";
import styles from "../translations/Translation.module.scss";
import AddNewTranslation from "./AddNewTranslation";
import crStyles from "../customerReservation/crStyles";
import TranslationsClient from "./TranslationsClient";
import { LANCODES } from "../../common/Constants";
import { COLLAPSE_TRANSLATIONS } from "../../common/CollapseTranslations";

class TranslationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewTranslation: false,
      showInput: true,
      isExpended:
        COLLAPSE_TRANSLATIONS.indexOf(props.translations.moduleKey) === -1
          ? true
          : false,
    };
  }

  onClick = () => {
    this.setState({ showNewTranslation: true });
    this.setState({ showInput: false });
  };

  cancelSpecificTranslation = () => {
    this.setState({ showNewTranslation: false });
    this.setState({ showInput: true });
  };

  addSpecificTranslation = (updatedLanguage) => {
    this.props.hideOrShow(true);
    TranslationsClient.postAddTranslations(
      updatedLanguage,
      this.props.getMarketsForNewTranslations(),
      this.props.token
    )
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        this.setState({ showInput: true });
        this.setState({ showNewTranslation: false });
        this.props.hideOrShow(false);
        this.props.getTranslations(this.props.currentLan);
      });
  };

  render() {
    return (
      <div>
        <br />
        <ExpansionPanel
          styles={crStyles.expansionPanel}
          defaultExpanded={this.state.isExpended}
        >
          <ExpansionPanelSummary style={crStyles.headerBar}>
            <div className={styles.moduleKey}>
              {this.props.translations.moduleKey}
            </div>

            <br />
          </ExpansionPanelSummary>
          <Grid container className={styles.translation_header}>
            <Grid item md={3} className={styles.lanHeader}>
              {this.props.t("Translations.key")}
            </Grid>
            <Grid item md={3} className={styles.lanHeader}>
              {LANCODES["en"]}
            </Grid>
            <Grid item md={3} className={styles.lanHeader}>
              {LANCODES[this.props.currentLan]}
            </Grid>
            <Grid item md={3} className={styles.lanHeader}>
              {this.props.t("Translations.action")}
            </Grid>
          </Grid>

          <div>
            {Object.keys(this.props.translations.languages).length !== 0
              ? Object.keys(this.props.translations.languages).map((lan) => {
                  return (
                    <TranslationSpecific
                      {...this.props}
                      language={this.props.translations.languages[lan]}
                      moduleKey={this.props.translations.moduleKey}
                      key={this.props.translations.languages[lan].key}
                    />
                  );
                })
              : null}
          </div>

          <div className={styles.translation_header}>
            <div
              style={{
                position: "relative",
                float: "left",
                paddingBottom: "15px",
              }}
            >
              {this.state.showInput ? (
                <Link
                  className={styles.newTranslation}
                  style={{ color: "#102b4e" }}
                  variant="contained"
                  onClick={this.onClick}
                >
                  {this.props.t("Translations.addNewTrans")}
                </Link>
              ) : null}
            </div>
          </div>
          {this.state.showNewTranslation ? (
            <AddNewTranslation
              {...this.props}
              moduleKey={this.props.translations.moduleKey}
              cancelSpecificTranslation={this.cancelSpecificTranslation}
              addSpecificTranslation={this.addSpecificTranslation}
            />
          ) : null}
        </ExpansionPanel>
      </div>
    );
  }
}

export default TranslationTable;
