import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders, getHeadersForCsv } from "../../utils/AuthorizationUtil";
import { BASE_URL, INVENTORY_SERVICE_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class NscReportingClient {
  static baseUrl = BASE_URL;
  static inventoryUrl = INVENTORY_SERVICE_URL;

  static downloadDealerActivationReportInCsv(user) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/activation-report`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static downloadDealerReservationSummary(user, market, catalog) {
    let url = `${this.baseUrl}/reports/reservation-summary-report?market=${market}`;
    if (catalog) {
      url = url + `&catalog=${catalog}`;
    }

    const universalBOM = "\uFEFF";
    return axios
      .get(url, getHeadersForCsv(user.token))
      .then((response) => encodeURIComponent(`${universalBOM}${response.data}`))
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static downloadInventoryPricingReport(user, data) {
    const url = `${this.inventoryUrl}/nsc/all-dealer-inventory-pricing-report`;

    return axios
      .post(url, data, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static downloadInventoryPricingHistoryReport(user, data) {
    const url = `${this.inventoryUrl}/nsc/all-dealer-inventory-pricing-history-report`;

    return axios
      .post(url, data, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
