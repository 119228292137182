import React, { Component } from "react";
import { base64FileDownload } from "../../utils/FileDownloadUtil";
import HasPermissionTo from "../../common/HasPermissionTo";
import { withTranslation } from "react-i18next";
import NscBusinessSettings from "./NscBusinessSettings";
import DealerBusinessSettings from "./DealerBusinessSettings";
import TermsAndConditionsClient from "../../shared/clients/TermsAndConditionsClient";
import PendingActionClient from "../../shared/clients/PendingActionClient";
import { TERMS_AND_CONDITIONS } from "../../common/Constants";

class BusinessSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDealerAcceptingTerms: false,
      acceptTermsAndConditions: {},
      isDataLoaded: false,
      termsAndConditionsHistory: [],
      enableUpload: undefined,
      restrictDropdown: false,
    };
    this.selectedTab = 0;
    this.filteredDealers = [];
    this.fromPending = false;

    this.pendingAction =
      this.props.location.query && this.props.location.query.fromPendingActions;
  }

  componentDidMount() {
    if (
      this.pendingAction &&
      this.props.location.actionName === TERMS_AND_CONDITIONS &&
      this.props.user.businessSettingsPage === -1
    ) {
      this.props.user.updateBusinessSettingsTab(0);
      this.fromPending = true;
    }
    this.filteredDealers = this.filterDealers();

    this.setState({ isDataLoaded: true });
  }

  handleTermsAndConditionsDownload = (termsandconditions) => {
    base64FileDownload(
      termsandconditions.file,
      "application/pdf",
      `${
        this.props.user.market.toUpperCase() +
        " " +
        this.props.t("TermsandConditionsHistory.documentName") +
        termsandconditions.version
      }.pdf`
    );
  };

  resetFromPending = () => {
    this.fromPending = false;
    this.setState({
      isDealerAcceptingTerms: false,
      restrictDropdown: false,
    });
  };

  alterComponentView = (termsAndConditions) => {
    const commonId = this.props.user.commonId;
    this.setState(
      {
        isDealerAcceptingTerms: !this.state.isDealerAcceptingTerms,
        acceptTermsAndConditions: termsAndConditions,
        restrictDropdown: !this.state.restrictDropdown,
      },
      () => {
        const acceptedDealers =
          this.state.acceptTermsAndConditions.acceptedDealers;
        let isCurrentDealerAcceptingTerms = false;
        acceptedDealers.forEach(function (dealer) {
          if (dealer === commonId) {
            isCurrentDealerAcceptingTerms = true;
          }
        });

        if (
          this.props.user.pendingActions.length === 0 &&
          isCurrentDealerAcceptingTerms
        ) {
          this.props.updateDealerGroupDetails();
        }
        if (this.fromPending) {
          this.props.history.push("/dashboard");
        } else {
          this.loadTermsAndConditionsHistory();
        }
      }
    );
  };

  deleteTerms = (version) => {
    const termsList = this.state.termsAndConditionsHistory;
    this.props.hideOrShow(true);
    TermsAndConditionsClient.deleteTermsAndConditions(this.props.user, version)
      .then(() => {
        this.addTermsAndConditionsPA(version);
        termsList.forEach((terms, index) => {
          if (terms.version === version) {
            termsList.splice(index, 1);
            this.setState({
              termsAndConditionsHistory: termsList,
              enableUpload: !(termsList.length > 1),
            });
          }
        });
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  addTermsAndConditionsPA = () => {
    this.props.hideOrShow(true);
    PendingActionClient.addPA(this.props.user, TERMS_AND_CONDITIONS).finally(
      () => {
        this.props.hideOrShow(false);
      }
    );
  };

  loadTermsAndConditionsHistory = () => {
    this.props.hideOrShow(true);
    TermsAndConditionsClient.retrieveTermsAndConditionsHistory(this.props.user)
      .then((data) => {
        this.setState({
          termsAndConditionsHistory: data,
          enableUpload: !(data.length > 1),
        });
      })
      .catch(() => {})
      .finally(() => {
        // If this is coming from the dashboard
        if (
          this.pendingAction &&
          this.props.location.actionName === "TERMS_CONDITIONS" &&
          !this.props.isNsc &&
          this.props.user.businessSettingsPage === 0 &&
          this.fromPending
        ) {
          const tnC = this.state.termsAndConditionsHistory
            .slice()
            .reverse()
            .find((terms) => !terms.accepted);
          this.setState({
            isDealerAcceptingTerms: true,
            restrictDropdown: true,
            acceptTermsAndConditions: tnC,
          });
        }
        this.setState({ isDataLoaded: true });
        this.props.hideOrShow(false);
      });
  };

  filterDealers() {
    const filteredDealers = [];
    if (this.props.dealerGroup) {
      const currentDealer = this.props.dealerGroup.filter(
        (dealer) => dealer.commonId === this.props.user.commonId
      )[0];
      if (
        this.props.user.showAll &&
        (currentDealer.dealerType === "HUB" ||
          "MDLR" === currentDealer.dealerType)
      ) {
        this.props.dealerGroup.forEach((dealer) => {
          filteredDealers.push(dealer);
        });
      } else {
        filteredDealers.push(currentDealer);
      }
    }
    return filteredDealers;
  }

  render() {
    if (this.state.isDataLoaded) {
      return (
        <div>
          <HasPermissionTo
            perform={["dealerBusinessSettings:view"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() => (
              <DealerBusinessSettings
                {...this.props}
                startingTab={this.selectedTab}
                restrictDropdown={this.state.restrictDropdown}
                isDealerAcceptingTerms={this.state.isDealerAcceptingTerms}
                fromPending={this.fromPending}
                resetFromPending={this.resetFromPending}
                termsAndConditions={this.state.acceptTermsAndConditions}
                alterComponentView={this.alterComponentView}
                loadTermsAndConditionsHistory={
                  this.loadTermsAndConditionsHistory
                }
                handleTermsAndConditionsDownload={
                  this.handleTermsAndConditionsDownload
                }
                termsAndConditionsHistory={this.state.termsAndConditionsHistory}
                filteredDealers={this.filteredDealers}
                isDealer={true}
              />
            )}
            noRender={() => (
              <NscBusinessSettings
                {...this.props}
                startingTab={this.selectedTab}
                restrictDropdown={this.state.restrictDropdown}
                handleTermsAndConditionsDownload={
                  this.handleTermsAndConditionsDownload
                }
                isDealer={false}
                enableUpload={this.state.enableUpload}
                deleteTerms={this.deleteTerms}
                loadTermsAndConditionsHistory={
                  this.loadTermsAndConditionsHistory
                }
                termsAndConditionsHistory={this.state.termsAndConditionsHistory}
              />
            )}
          />
        </div>
      );
    }
    return null;
  }
}

export default withTranslation("emp")(BusinessSettings);
